import type {Folder} from '@/types'
import {IconButton, useModal} from '@gicortex/nucleus'
import {Upload} from 'lucide-react'
import {UploadFileModal} from './upload-file-modal'

export interface UploadFileButtonProps {
  folder: Folder
  pathPrefix: string
  selectOnUpload: boolean
}
export const UploadFileButton = ({
  folder,
  pathPrefix,
  selectOnUpload = false,
}: UploadFileButtonProps) => {
  const {openModal} = useModal()

  const handleClick = () => {
    openModal(
      <UploadFileModal
        folder={folder}
        pathPrefix={pathPrefix}
        selectOnUpload={selectOnUpload}
      />,
    )
  }

  return (
    <IconButton
      icon={Upload}
      onClick={handleClick}
      tooltip='Upload File'
    />
  )
}
