import {ButtonGroup} from '@/components/button-group'
import {MoveToFolderButton} from '@/components/move-to-folder-button'
import {FILE_PROCESSING, FolderType} from '@/configs'
import {filesSlice} from '@/store/files'
import type {DataBucketFile, RootState} from '@/types'
import type {FileProcessingStatus} from '@/types'
import {fileUtil} from '@/utils'
import {
  Icon,
  Row,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  cn,
} from '@gicortex/nucleus'
import {Dot} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'
import {DeleteFileButton} from './delete-file-button'
import {MoveFileModal} from './move-file-modal'
import {PreviewButton} from './preview-button'
import {ReprocessFileButton} from './reprocess-button'

export interface FileItemProps {
  file: DataBucketFile
  pathPrefix: string
}
export const FileItem = ({file, pathPrefix}: FileItemProps) => {
  const dispatch = useDispatch()
  const storeFiles = useSelector((state: RootState) => state.files.queue)

  const name = fileUtil.getName(file, {shouldRemoveUuidPrefix: true})

  const getFileDetails = ({file}: {file: DataBucketFile}) => {
    const {status, color, description} = FILE_PROCESSING
    if (file.status === status.success) {
      return fileUtil.getStatusProps(status.success)
    }
    const fileInStore = storeFiles.find(
      (storeFile) => storeFile.file === file.file,
    )
    if (fileInStore) {
      const fileInStoreValue = fileUtil.getStatusProps(
        fileInStore.status as FileProcessingStatus,
      )
      return {
        status: fileInStoreValue.status,
        color: fileInStoreValue.color,
        description: fileInStoreValue.description,
      }
    }
    return {
      status: status.failure,
      color: color.failure,
      description: description.failure,
    }
  }

  const fileDetails = getFileDetails({file})

  const handleSelect = () => {
    dispatch(filesSlice.actions.selectFile(file))
  }

  return (
    <Row
      className={cn(
        'gap-2 group justify-between p-1 rounded-lg text-sm',
        'hover:bg-indigo-100 hover:cursor-pointer',
        'active:bg-indigo-50',
      )}
      key={file.id}
      onClick={handleSelect}
    >
      <Row className='truncate'>
        <Icon
          className={cn('mr-1 pointer-events-auto', fileDetails.color)}
          component={Dot}
          strokeWidth='12'
          tooltip={fileDetails.description}
        />
        <Tooltip>
          <TooltipTrigger asChild={true}>
            <p className='truncate'>{name}</p>
          </TooltipTrigger>
          <TooltipContent>{name}</TooltipContent>
        </Tooltip>
      </Row>
      <ButtonGroup
        className={cn(
          'align-center hidden ml-auto space-x-2',
          'group-hover:flex',
          '[&_button]:p-0',
        )}
      >
        {fileDetails.status === FILE_PROCESSING.status.failure && (
          <ReprocessFileButton file={file} />
        )}
        <PreviewButton file={file} />
        <MoveToFolderButton
          folderType={FolderType.FILES}
          modal={
            <MoveFileModal
              file={file}
              pathPrefix={pathPrefix}
            />
          }
          pathPrefix={pathPrefix}
        />
        <DeleteFileButton file={file} />
      </ButtonGroup>
    </Row>
  )
}
