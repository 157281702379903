import {type Message, useGetUsersMeQuery} from '@/api'
import {ROLE} from '@/configs'
import {Footer} from '@/features/chat/footer'
import {Messages} from '@/features/chat/messages'
import {PromptInput} from '@/features/chat/prompt-input'
import {useUploadFiles} from '@/hooks/upload-files'
import {filesSlice} from '@/store/files'
import type {Folder, Role, RootState} from '@/types'
import {
  DropzoneOverlay,
  TabGroup,
  TabPanel,
  TabPanels,
  useDropzone,
} from '@gicortex/nucleus'
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const YOUR_FILES_ROOT_FOLDER: Folder = {
  children: [],
  name: 'Personal',
  path: 'Your Files/',
}

export const Chat = () => {
  const dispatch = useDispatch()
  const {uploadFiles} = useUploadFiles()
  const {messages} = useSelector((state: RootState) => state.current)
  const messagesRef = useRef<HTMLDivElement | null>(null)

  const {data: me} = useGetUsersMeQuery()

  useEffect(() => {
    const handleScroll = () => {
      if (messagesRef.current) {
        messagesRef.current.scrollTo({
          top: messagesRef.current.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
    const lastMessage = messages[messages.length - 1] as Message & {
      source: Role
    }
    if (lastMessage?.source === ROLE.USER) {
      handleScroll()
    }
  }, [messages])

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  useEffect(() => {
    if (selectedFiles.length > 0)
      dispatch(filesSlice.actions.selectOnUpload({selectOnUpload: true}))
  })

  const onDrop = async (files: File[]) => {
    if (!me) throw new Error('Me is required')
    const folder: Folder = {
      ...YOUR_FILES_ROOT_FOLDER,
    }
    setSelectedFiles(files)
    await uploadFiles({files, folder})
  }

  const {isDragActive, handleDragOver, handleDragLeave, handleDrop} =
    useDropzone({onDrop})

  return (
    <TabGroup
      className='h-full grid grid-rows-[1fr_auto] min-w-80 overflow-y-auto w-full'
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <DropzoneOverlay visible={isDragActive} />
      <TabPanels className='overflow-y-auto mt-0'>
        <TabPanel className='h-full !py-0'>
          <div
            className='h-full overflow-y-auto'
            ref={messagesRef}
          >
            <Messages />
          </div>
        </TabPanel>
      </TabPanels>
      <PromptInput />
      <Footer />
    </TabGroup>
  )
}
