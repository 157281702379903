import {type CreateDatabucketFileIndexApiArg, type FileStatus, api} from '@/api'
import {FILE_PROCESSING} from '@/configs'
import type {DataBucketFile, FileProcessingStatus, RootState} from '@/types'
import type {DataBucketFileWithRetryAttempts} from '@/types/data-bucket-file'
import {asyncUtil, fileUtil} from '@/utils'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

const initialState: {
  queue: DataBucketFileWithRetryAttempts[]
  selectedFiles: DataBucketFileWithRetryAttempts[]
  isInitialized: boolean
  selectOnUpload: boolean
} = {
  queue: [],
  selectedFiles: [],
  isInitialized: false,
  selectOnUpload: false,
}

const {polling, status} = FILE_PROCESSING

const selectFileInQueue = (
  file: File,
  queue: DataBucketFileWithRetryAttempts[],
  selectedFiles: DataBucketFileWithRetryAttempts[],
) => {
  const queueFile = queue.find((f: DataBucketFileWithRetryAttempts) =>
    f.path.includes(file.name),
  )
  if (
    queueFile &&
    !selectedFiles.find(
      (f: DataBucketFileWithRetryAttempts) => f.path === queueFile.path,
    )
  ) {
    selectedFiles.push(queueFile)
  }
}
export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    addFiles(state, action: PayloadAction<{files: DataBucketFile[]}>) {
      const {files} = action.payload
      const filesWithRetryAttempts = files.map((file) => ({
        ...file,
        retryAttempts: 0,
      }))
      state.queue.push(...filesWithRetryAttempts)
    },
    setInitialized(state, action: PayloadAction<{isInitialized: boolean}>) {
      state.isInitialized = action.payload.isInitialized
    },
    selectOnUpload: (
      state,
      action: PayloadAction<{selectOnUpload: boolean}>,
    ) => {
      state.selectOnUpload = action.payload.selectOnUpload
    },
    selectFile: (state, action) => {
      const file = action.payload
      selectFileInQueue(file, state.queue, state.selectedFiles)
    },
    selectFiles: (state, action: PayloadAction<{files: File[]}>) => {
      const {files: filesToSelect} = action.payload
      for (const file of filesToSelect) {
        selectFileInQueue(file, state.queue, state.selectedFiles)
      }
    },
    removeSelectedFile: (state, action) => {
      state.selectedFiles = state.selectedFiles.filter(
        (file) => file.path !== action.payload.path,
      )
    },
    clearSelectedFiles: (state) => {
      state.selectedFiles = []
    },
    removeFile(
      state,
      action: PayloadAction<{filePath: DataBucketFile['path']}>,
    ) {
      const {filePath} = action.payload
      state.queue = state.queue.filter((f) => f.path !== filePath)
    },
    setFiles(state, action: PayloadAction<{files: DataBucketFile[]}>) {
      const {files: serverFiles} = action.payload
      for (const serverFile of serverFiles) {
        const localFile = state.queue.find((f) => f.file === serverFile.file)
        const selectedFile = state.selectedFiles.find(
          (selectedFile) => selectedFile.file === serverFile.file,
        )
        if (localFile) {
          if (localFile.retryAttempts < polling.maxAttempts) {
            localFile.path = serverFile.path
            localFile.status = (
              serverFile.status !== status.success
                ? status.pending
                : status.success
            ) as FileStatus
            if (selectedFile) {
              selectedFile.path = serverFile.path
              selectedFile.status = (
                serverFile.status === status.success
                  ? status.success
                  : status.pending
              ) as FileStatus
            }
          }
        } else {
          state.queue.push({
            ...serverFile,
            status: (serverFile.status !== status.success
              ? status.pending
              : status.success) as FileStatus,
            retryAttempts: 0,
          })
        }
      }
    },
    setFileStatus(
      state,
      action: PayloadAction<{filePath: string; status: FileProcessingStatus}>,
    ) {
      const {filePath, status} = action.payload
      const file = state.queue.find((f) => {
        return f.path === filePath
      })
      const selectedFile = state.selectedFiles.find((f) => {
        return f.path === filePath
      })
      if (file) {
        file.status = status as FileStatus
      }
      if (selectedFile) {
        selectedFile.status = status as FileStatus
      }
    },
    incrementRetryAttempts(
      state,
      action: PayloadAction<{filePath: DataBucketFile['path']}>,
    ) {
      const {filePath} = action.payload
      const file = state.queue.find((f) => f.path === filePath)
      const selectedFile = state.selectedFiles.find(
        (selectedFile) => selectedFile.path === filePath,
      )

      if (!file) return
      if (file.retryAttempts < polling.maxAttempts) {
        file.retryAttempts++
        if (selectedFile) {
          selectedFile.retryAttempts++
        }
      }
      if (file.retryAttempts >= polling.maxAttempts) {
        file.status = status.failure as FileStatus
        if (selectedFile) {
          selectedFile.status = status.failure as FileStatus
        }
      }
    },
    resetRetryAttempts(
      state,
      action: PayloadAction<{filePath: DataBucketFile['path']}>,
    ) {
      const {filePath} = action.payload
      const file = state.queue.find((f) => f.path === filePath)
      const selectedFile = state.selectedFiles.find(
        (selectedFile) => selectedFile.path === filePath,
      )
      if (file) {
        file.retryAttempts = 0
        file.status = status.pending as FileStatus
        if (selectedFile) {
          selectedFile.retryAttempts = file.retryAttempts
          selectedFile.status = status.pending as FileStatus
        }
      }
    },
  },
})

export const initializeFiles = createAsyncThunk(
  'files/initializeFiles',
  async (_, {dispatch}) => {
    dispatch(pollFileStatuses())
  },
)

export const retriggerFailedFiles = createAsyncThunk(
  'files/retriggerFailedFiles',
  async (_, {dispatch, getState}) => {
    const state = getState() as RootState
    const files = state.files.queue

    const failedFiles = files.filter(
      (file) =>
        file.status !== status.success &&
        file.retryAttempts < polling.maxAttempts,
    )

    if (failedFiles.length === 0) return

    for (const file of failedFiles) {
      await dispatch(
        api.endpoints.createDatabucketFileIndex.initiate({
          databucketName: file.bucket,
          dataBucketFileIndexRequest: {path: file.path},
        }),
      )
    }
  },
)

export const pollFileStatuses = createAsyncThunk(
  'files/pollFileStatuses',
  async (_, {dispatch, getState}) => {
    const {shouldContinuePolling} = fileUtil
    const {incrementRetryAttempts, setFiles} = filesSlice.actions

    while (shouldContinuePolling((getState() as RootState).files.queue)) {
      await asyncUtil.sleep(polling.interval)

      const {data = []} = await dispatch(
        api.endpoints.getDatabucketFiles.initiate(_, {forceRefetch: true}),
      )

      dispatch(setFiles({files: data}))

      const filePaths = (getState() as RootState).files.queue.map(
        (file) => file.path,
      )
      for (const path of filePaths) {
        const localFile = (getState() as RootState).files.queue.find(
          (f) => f.path === path,
        )
        if (!localFile) continue
        if (
          localFile.retryAttempts < polling.maxAttempts &&
          localFile.status !== status.success
        ) {
          dispatch(incrementRetryAttempts({filePath: localFile.path}))
        }
      }
    }
  },
)

export const retryFile = createAsyncThunk(
  'files/retryFile',
  async (
    {
      dataBucketFileIndexRequest,
      databucketName,
    }: CreateDatabucketFileIndexApiArg,
    {dispatch},
  ) => {
    const {setFileStatus, resetRetryAttempts} = filesSlice.actions
    const {path} = dataBucketFileIndexRequest

    await dispatch(
      api.endpoints.createDatabucketFileIndex.initiate({
        databucketName,
        dataBucketFileIndexRequest,
      }),
    )
    dispatch(setFileStatus({filePath: path, status: status.pending}))
    dispatch(resetRetryAttempts({filePath: path}))
    dispatch(pollFileStatuses())
  },
)
