import {
  type ChatFileResponse,
  type ChatResponse,
  type DataBucketFileResponse,
  type PromptResponse,
  api,
  useCreateMessageMutation,
  useCreateNewChatInputMutation,
} from '@/api'
import {focusPrompt} from '@/components/send-prompt-button'
import {APP_STATE, ROLE, TAG_TYPE} from '@/configs'
import {PromptContext} from '@/providers/prompt'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {fileUtil, randomUtil} from '@/utils'
import {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'

interface SendPromptParams {
  prompt?: PromptResponse | string
}

export const useCurrentPrompt = () => {
  const current = useSelector((state: RootState) => state.current)
  const queue = useSelector((state: RootState) => state.files)
  const dispatch = useDispatch()
  const [sendRagQuery] = useCreateMessageMutation()
  const [sendChatQuery] = useCreateNewChatInputMutation()
  const promptContext = useContext(PromptContext)
  return {
    ...promptContext,
    async sendPrompt({prompt}: SendPromptParams = {}) {
      try {
        // get selected files
        const files = queue.selectedFiles.map(
          (file: Partial<DataBucketFileResponse>) =>
            ({
              // @ts-ignore
              bucket: file.bucketName,
              path: file.path,
            }) as ChatFileResponse,
        )
        // add the message to the chat
        const userMessage: Partial<ChatResponse> = {
          id: randomUtil.uuid(),
          source: ROLE.USER,
          message: typeof prompt === 'string' ? prompt : prompt?.prompt,
          // @ts-ignore
          files,
          createdAt: Date(),
          updatedAt: Date(),
        }
        if (current.historyId) {
          userMessage.historyId = current.historyId
        }
        dispatch(currentSlice.actions.addMessage(userMessage))
        // show the loading indicator
        dispatch(
          currentSlice.actions.setAppState(
            APP_STATE.WAITING_FOR_AGENT_RESPONSE,
          ),
        )
        focusPrompt()
        promptContext.resetPrompt()
        const sourceIds = queue.selectedFiles.map((file) =>
          fileUtil.getSourceId(file),
        )
        const userPrompt = userMessage.message as string

        let agentMessage = null
        if (sourceIds.length > 0) {
          agentMessage = await sendRagQuery({
            requestMessage: {
              from: ROLE.User,
              historyId: current.historyId,
              prompt: userPrompt,
              sourceIds,
            },
          }).unwrap()
        } else {
          agentMessage = await sendChatQuery({
            createChatRequest: {
              input: userPrompt,
              historyId: current.historyId,
              modelType: 'fast',
            },
          }).unwrap()
        }
        dispatch(api.util.invalidateTags([TAG_TYPE.CHAT_HISTORY]))
        dispatch(
          currentSlice.actions.addMessage({
            ...agentMessage,
            source: ROLE.AGENT,
          }),
        )
        if (!current.historyId) {
          dispatch(currentSlice.actions.setHistoryId(agentMessage.historyId))
        }
      } catch (error) {
        console.error(error)
        // show error toast
      } finally {
        // hide the loading indicator
        dispatch(
          currentSlice.actions.setAppState(APP_STATE.WAITING_FOR_USER_INPUT),
        )
      }
    },
  }
}
