import type {Message} from '@/api'
import {APP_STATE, SidebarTab} from '@/configs'
import type {AppState, DataBucketFile} from '@/types'
import {createSlice} from '@reduxjs/toolkit'

const initialState: {
  appState: AppState
  historyId?: string
  messages: Message[]
  selectedSidebarTab: SidebarTab
  uploadingFiles: (DataBucketFile & {id: string})[]
  uploadingGroup: {id?: string; name?: string}
} = {
  appState: APP_STATE.WAITING_FOR_USER_INPUT,
  historyId: undefined,
  messages: [],
  selectedSidebarTab: SidebarTab.HISTORY,
  uploadingFiles: [],
  uploadingGroup: {},
}

export const currentSlice = createSlice({
  name: 'current',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload)
    },
    reset: () => initialState,
    setAppState: (state, action) => {
      state.appState = action.payload
    },
    setHistoryId: (state, action) => {
      state.historyId = action.payload
    },
    setMessages: (state, action) => {
      state.messages = action.payload
    },
    setSelectedSidebarTab: (state, action) => {
      state.selectedSidebarTab = action.payload
    },
    setUploadingFiles: (state, action) => {
      state.uploadingFiles = action.payload
    },
    setUploadingGroup: (state, action) => {
      state.uploadingGroup = action.payload
    },
    removeUploadingFiles: (state, action) => {
      state.uploadingFiles = state.uploadingFiles.filter(
        (file) => !action.payload.includes(file.id),
      )
    },
  },
})
